@charset "UTF-8";

html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video {margin: 0;padding: 0;border: 0;font-size: 100%;vertical-align: baseline;background: transparent;}
body {font-size: 1em;line-height: 1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {display: block;}
nav ul,li {list-style: none;}
a {margin: 0;padding: 0;font-size: 100%;text-decoration: none;vertical-align: baseline;color: #666;background: transparent;cursor: pointer;}
a:hover,a:focus {text-decoration:underline}
img {vertical-align: top;max-width: 100%;}
table {border-collapse: collapse;border-spacing: 0;}
input {margin: 0;padding: 0;box-sizing: content-box;vertical-align: top;border-radius: 0;}
header,nav,section,article,aside,footer {display: block;}
sub {font-size: 10px}

::selection {
    background: #000;
    color: #fff;
}